<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-50 md-medium-size-100">
      <md-card>
        <!--        <md-card-header>-->
        <!--          <h4 class="title">Collapsible Accordion</h4>-->
        <!--        </md-card-header>-->
        <md-card-content>
          <collapse
              :collapse="collapse"
              icon="keyboard_arrow_down"
              color-collapse="success"
          >
            <template v-for="(item, index) in collapse" :slot="`md-collapse-pane-${index+1}`">
              <p v-for="(doc)  in list[index].docs" :key="item.name + doc.title">
                <a :href="doc.link" target="_blank">{{ doc.title }}</a>
              </p>

            </template>
          </collapse>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import {Collapse} from "@/components"

export default {
  name: "Manuals",
  components: {
    Collapse
  },
  data() {
    return {
      list: [
        {
          name: "BAMS",
          docs: [{
            title: "Bridge Inspection Manual",
            link: "https://svg-rms.solidstreet.eu/files/manuals/bms/Bridge Inspection Manual.pdf"
          },
            {
              title: "BMS User Manual",
              link: "https://svg-rms.solidstreet.eu/files/manuals/bms/BMS User Manual.pdf"
            }]
        },
        {
          name: "Training Slides",
          docs: [{
            title: "Bridge Management",
            link: "https://svg-rms.solidstreet.eu/files/manuals/training_slides/Bridge-management.pdf"
          },]
        }
      ]
    }
  },
  computed: {
    collapse() {
      return this.list.map(item => item.name)
    }
  }
}
</script>

<style scoped>

</style>
